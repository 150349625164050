import { defineStore } from 'pinia';
import { toHandlers } from 'vue';

export const useAnswersStore = defineStore({
    'id': 'answers',
    state: () => ({
        consent: false,
        answers: [],
        completed: [],
        currentQuestionaire: "", // add this to answers answer object so we can filter out results per questionaire
        isDebug: false
    }),
    actions: {
        addPoints(points, answer, answerid, index, multi ) {

           // check if we have an existing entry in the answers object
           const existing = this.answers.findIndex((e) => {
            return e.id == answerid
           })

           //create tags object
           const tagsObject = {}
           answer.tags.forEach( (name) => {
             tagsObject[name] = Number(points)
           } )

           
            if( existing >= 0 ) { //we're updating

                if( multi ) {
                    
                    if( this.answers[existing].points > points ) {
                        this.answers[existing].points = points
                    }

                    if( this.answers[existing].options.includes(index) ) {
                        
                        //remove element from array and recalculate points
                        this.answers[existing].options = this.answers[existing].options.filter(
                            e => e != index
                        )

                        if(this.answers[existing].options.length == 0 ) {
                            
                            this.answers = this.answers.filter( (e, i)  => i != existing )

                            return

                        } else {

                            const maxpoints = this.answers[existing].options.reduce(
                                (previous, current) => {
                                    return answer.options[previous].points > answer.options[current].points ? answer.options[current].points : answer.options[previous].points
                                }
                                ,0 
                            )
    
                            this.answers[existing].points = maxpoints
                        }


                    } else {
                        this.answers[existing].options.push(index)
                    }

                } else {
                    this.answers[existing].points = points
                    this.answers[existing].options = [index]
                }
                
                this.answers[existing].tags = tagsObject

            } else { //we're creating

               this.answers.push({
                   id:answerid,
                   questionaire: this.currentQuestionaire,
                   points: points,
                   tags: tagsObject,
                   options: [index] 
               })
           
           }

        }
    }, 
    getters: {
        result: (state) => {

            const result = {}

            state.answers.forEach( (e) => {
            
                if(result[e.questionaire] === undefined) {
                    result[e.questionaire] = 0
                }

                result[e.questionaire] += Number(e.points)
            })

            return result

        },
        tags: (state) => {
            
            const result = {}

            state.answers.forEach( (e) => {
                
                for( const name in e.tags) {

                    if(result[name] === undefined) {
                        result[name] = 0
                    }

                    result[name] += e.tags[name]
                }
            })


            return result

        }
    },
    persist: true

})